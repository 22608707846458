import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { ReactMediaLibrary } from "react-media-library";
import axios from "axios";
import { BASE_URL } from "../../../Redux/urls";

const TextEditor = (props) => {
  const [display, setDisplay] = useState(false);
  const [fileLibraryList, setFileLibraryList] = useState([]);

  useEffect(() => {
    // TODO Get file list from database
    setFileLibraryList([
      {
        _id: 1,
        title: "Me and my dog",
        size: 294880,
        fileName: "img_3549.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:29.866Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 2,
        title: "2019 Summer Vacation",
        size: 864483,
        fileName: "201702.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:45.018Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 3,
        title: "Our new baby",
        size: 586458,
        fileName: "271092.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:19:33.498Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 4,
        title: "My new car",
        size: 894665,
        fileName: "photo-107.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:28:39.723Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 5,
        title: "Me and my dog",
        size: 294880,
        fileName: "img_3549.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:29.866Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 6,
        title: "2019 Summer Vacation",
        size: 864483,
        fileName: "201702.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:45.018Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 7,
        title: "Our new baby",
        size: 586458,
        fileName: "271092.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:19:33.498Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 8,
        title: "My new car",
        size: 894665,
        fileName: "photo-107.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:28:39.723Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 5,
        title: "Me and my dog",
        size: 294880,
        fileName: "img_3549.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:29.866Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 6,
        title: "2019 Summer Vacation",
        size: 864483,
        fileName: "201702.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:45.018Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 7,
        title: "Our new baby",
        size: 586458,
        fileName: "271092.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:19:33.498Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 8,
        title: "My new car",
        size: 894665,
        fileName: "photo-107.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:28:39.723Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 5,
        title: "Me and my dog",
        size: 294880,
        fileName: "img_3549.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:29.866Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 6,
        title: "2019 Summer Vacation",
        size: 864483,
        fileName: "201702.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:45.018Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 7,
        title: "Our new baby",
        size: 586458,
        fileName: "271092.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:19:33.498Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 8,
        title: "My new car",
        size: 894665,
        fileName: "photo-107.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:28:39.723Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 7,
        title: "Our new baby",
        size: 586458,
        fileName: "271092.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:19:33.498Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
      {
        _id: 8,
        title: "My new car",
        size: 894665,
        fileName: "photo-107.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:28:39.723Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg",
      },
    ]);
  }, []);
  //https://fis-api.flamingoitstudio.net/api-admin/admin/attachment
  const uploadCallback = async (file, meta) => {
    const bolb = await fetch(file).then((res) => res.blob());
    console.log(bolb);

    // TODO Upload file to backend APIs
    const result = await axios.post(
      `${BASE_URL}api/v1/admin/attachment/add`,
      { attachment: bolb },
      {
        headers: {
          Authorization: `${localStorage.getItem(
            "token_type"
          )} ${localStorage.getItem("access_token")}`,
        },
      }
    );

    if (result.success) {
      // New file uploaded
      // TODO Reacquire new file list from database
      //const newFileList = await getNewFileList();
      //setFileLibraryList(newFileList);

      // Return true to display upload success in modal
      return true;
    } else {
      // Return false to display upload failed in modal
      return false;
    }
  };

  const selectCallback = async (item) => {
    // Hide modal
    setDisplay(false);
    console.info(item);
    //clientSelectCallback(item);
  };

  // const deleteCallback = async(item) => {
  //   // TODO Delete file from backend service
  //   const result = await fileDelete(item);

  //   if (result.success) {
  //     // Deleted file
  //     // TODO Reacquire file list from database
  //     const newFileList = await getNewFileList();
  //     setFileLibraryList(newFileList);
  //   }
  // }

  const handleEditorChange = (e) => {
    console.log("e", e);
    console.log("Content was updated:", e.target.getContent());
  };

  // const myFileBrowser = (field_name, url, type, win) => {

  //   // alert("Field_Name: " + field_name + "nURL: " + url + "nType: " + type + "nWin: " + win); // debug/testing

  //   /* If you work with sessions in PHP and your client doesn't accept cookies you might need to carry
  //      the session name and session ID in the request string (can look like this: "?PHPSESSID=88p0n70s9dsknra96qhuk6etm5").
  //      These lines of code extract the necessary parameters and add them back to the filebrowser URL again. */

  //   var cmsURL = window.location.toString();    // script URL - use an absolute path!
  //   if (cmsURL.indexOf("?") < 0) {
  //       //add the type as the only query parameter
  //       cmsURL = cmsURL + "?type=" + type;
  //   }
  //   else {
  //       //add the type as an additional query parameter
  //       // (PHP session ID is now included if there is one at all)
  //       cmsURL = cmsURL + "&type=" + type;
  //   }

  //   tinyMCE.activeEditor.windowManager.open({
  //       file : cmsURL,
  //       title : 'My File Browser',
  //       width : 420,  // Your dimensions may differ - toy around with them!
  //       height : 400,
  //       resizable : "yes",
  //       inline : "yes",  // This parameter only has an effect if you use the inlinepopups plugin!
  //       close_previous : "no"
  //   }, {
  //       window : win,
  //       input : field_name
  //   });
  //   return false;
  // }

  const editorConfig = {
    ...(props.simpleTollbar
      ? {
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "H2 H3 | " +
            "bold italic | bullist numlist | " +
            "removeformat link",
        }
      : {
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | image media | code",
          //file_browser_callback: myFileBrowser,
          //file_browser_callback_types: 'image',
          file_picker_callback: function (callback, value, meta) {
            if (meta.filetype == "image") {
              //var input = document.getElementById("my-file");
              setDisplay(true)
              console.log(value);
             // input.click();
              // input.onchange = function () {
              //   var file = input.files[0];
              //   var reader = new FileReader();
              //   reader.onload = function (e) {
              //     callback(e.target.result, {
              //       alt: file.name,
              //     });
              //   };
              //   reader.readAsDataURL(file);
              // };
            }
          },
          file_picker_types: 'file image media',
          image_advtab: true,
          image_caption: true,
          paste_data_images: false,
          //paste_data_images: true,
        }),
  };
  return (
    <>
      {/* <input
        id="my-file"
        type="file"
        name="my-file"
        style={{ display: "none" }}
        onChange={() => setDisplay(true)}
      /> */}
      <Editor
        apiKey={process.env.REACT_APP_TinyMCE_apiKey}
        value={props.value}
        initialValue={props.initialValue ? props.initialValue : null}
        init={{
          ...editorConfig,
          ...(props.inline && {
            inline: true,
          }),
          ...(props.placeholder && { placeholder: props.placeholder }),
        }}
        onEditorChange={props.onChange}
        onChange={handleEditorChange}
      />
      <ReactMediaLibrary
        show={display}
        onHide={() => setDisplay(false)}
        fileUploadCallback={uploadCallback}
        fileLibraryList={fileLibraryList}
        fileSelectCallback={selectCallback}
        modalTitle=""
        //fileDeleteCallback={deleteCallback}
      />
    </>
  );
};

export default TextEditor;
